import React, { useEffect, useState } from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Link from 'src/components/link/index'
import QandA from 'src/components/qanda/index'
import ReadyToGo from 'src/components/readytogo/index'

import { get } from 'src/lib/api'

import styles from './index.module.scss'

const FaqPage = () => {
	const [items, setItems] = useState([])

	useEffect(() => {
		if (typeof window !== `undefined`) {
			get(`/app/data/faq/all.json`).then(setItems)
		}
	}, [setItems])

	return (
		<div className={styles.faq}>
			<SEO title="FAQ" />
			<Header hasBack={true} />

			<Title
				h1="FAQ"
				right={
					<ul className={styles.faqTypes}>
						{items.map((group, i) => (
							<li key={i}>
								<Link to={`/faq/#${group.slug}`}>{group.type}</Link>
							</li>
						))}
					</ul>
				}
			/>

			<Inner className={styles.faqBody}>
				{items.map((group, i) => (
					<section key={i} id={group.slug}>
						<h2>{group.type}</h2>
						<ul>
							{group.nodes.map((node, j) => (
								<li key={j}>
									<QandA q={node.q} a={node.a} />
								</li>
							))}
						</ul>
					</section>
				))}
			</Inner>

			<ReadyToGo />
		</div>
	)
}

export default FaqPage
